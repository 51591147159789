import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'


const AboutUs = ({location}) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Profile" />
      <div class="container mt-4">
        <div class="row">
          <div class="col"></div>
          <div class="col-12 text-justify">
            <p>
              HighQ Flat Pack (<abbr>HQFP</abbr>) is a Flat Pack manufacturing
              company situated in Bayswater, Victoria.
            </p>
            <p>
              <abbr>HQFP</abbr> can:
              <ul>
                <li>
                  Supply all types of joinery, with or without hardware, as flat
                  pack for DIY, Cabinet Makers and Builders.
                </li>
                <li>
                  Cut and edge all types of boards from{' '}
                  <a href="https://www.polytec.com.au/">Polytec</a>,{' '}
                  <a href="https://www.laminex.com.au/">Laminex</a>,{' '}
                  <a href="https://www.laminex.com.au/about-laminex/brands/formica">
                    Formica
                  </a>
                  ,
                  <a href="https://www.timberwood.com.au/">
                    Timber Wood Panels
                  </a>
                  , <a href="https://nikpol.com.au/decorative/">Egger</a>. We
                  also cut and edge boards supplied by customers and delivered
                  to our factory in{' '}
                  <a href="https://www.google.com/maps/place/37+Industry+Pl,+Bayswater+VIC+3153,+Australia/@-37.8425055,145.2759371,3a,75y,187.69h,90t/data=!3m7!1e1!3m5!1sCKBi9Ak_3iqygSfTV3yEDg!2e0!6s%2F%2Fgeo0.ggpht.com%2Fcbk%3Fpanoid%3DCKBi9Ak_3iqygSfTV3yEDg%26output%3Dthumbnail%26cb_client%3Dsearch.TACTILE.gps%26thumb%3D2%26w%3D86%26h%3D86%26yaw%3D187.69212%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656!4m5!3m4!1s0x6ad63b0ca869038d:0x612dd1df9c2ca5f0!8m2!3d-37.8426248!4d145.2759163">
                    Bayswater
                  </a>
                  .
                </li>
                <li>
                  Provide full material list reports so you can do your own
                  shopping for hardware and a detailed SQM painting report for
                  your 2 Pack products to keep the job costs under control.
                </li>
                <li>
                  Offer Designing Services using the latest Cabinet Vision
                  software to help you present the job to your customers in a
                  professional manner.
                </li>
                <li>
                  Provide working space for knock up and storage space for the
                  cabinets cut with us. In collaboration with a delivery company
                  you can have your cabinets picked up and delivered on site
                  ready to be installed, without taking them to another
                  premises.
                </li>
                <li>
                  Cut, route and drill MDF Doors matching the cabinets'
                  drilling, ready for painting.
                </li>
              </ul>
              We use a high precision 3600 x 1800 German CNC flat bed with auto
              labeling printer to maximize the sheet use and provide detailed
              information to make your projects more efficient.
            </p>
            <p>
              <abbr>HQFP</abbr> uses the latest Cabinet Vision Software for cabinet
              manufacturing with the latest hardware database. We can provide
              full comprehensive drilling for most hardware on the market like
              Blum Corner Space with or without servo drive, Blum Aventos range
              with servomechanism, Hafele Free systems, Lazy Susan Systems and
              many more. We cut different customizable shelves shapes like C
              shape, U shape or L shape. If we do not have it in our database,
              send us your installation specifications and we will make it
              happen.
            </p>
            <p>
              For price information please use this <a href="/PriceList170919.pdf">link</a>. If you want to place
              an order, <a href="/order-form">download</a> the Order Form and send it to{' '}
              <a href="mailto:orders@HighQFlatPack.com.au">
                orders@HighQFlatPack.com.au
              </a>
            </p>
            <p>
              {' '}
              For more information please email us at{' '}
              <a href="mailto:info@HighQFlatPack.com.au">
                info@HighQFlatPack.com.au
              </a>{' '}
              or give us a call at{' '}
              <a href="tel:1300 00 4737">1300 00 4737 (1300 00 HQFP)</a>.
            </p>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </Layout>
  )}

  export default AboutUs